import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { searchIcon } from "@debionetwork/ui-icons";
import { generalDebounce } from "@/common/lib/utils";
import { getOrderList } from "@/common/lib/api";
import { formatPrice, formatUSDTE } from "@/common/lib/price-format.js";
import { getMenstrualSubscriptionPrices } from "@/common/lib/polkadot-provider/query/menstrual-subscription";
export default {
  name: "CustomerPaymentHistory",
  data: () => ({
    searchIcon,
    formatPrice,
    formatUSDTE,
    searchQuery: "",
    paymentHeaders: [{
      text: "Service Name",
      value: "service_info.name",
      sortable: true
    }, {
      text: "Service Provider",
      value: "provider",
      sortable: true
    }, {
      text: "Order Date",
      value: "created_at",
      sortable: true
    }, {
      text: "Price",
      value: "price",
      sortable: true
    }, {
      text: "Status",
      value: "status",
      align: "right",
      sortable: true
    }, {
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "center",
      width: "5%"
    }],
    payments: []
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.metamask.web3,
      lastEventData: state => state.substrate.lastEventData
    })
  },
  watch: {
    searchQuery(newVal, oldVal) {
      if (newVal === "" && oldVal) this.metamaskDispatchAction(this.onSearchInput);
    },
    lastEventData(event) {
      if (!event) return;
      const methodToRefetch = ["OrderCreated", "OrderCancelled"];
      if (methodToRefetch.includes(event.method)) getOrderList();
    }
  },
  async created() {
    await this.onSearchInput();
  },
  methods: {
    onSearchInput: generalDebounce(async function (val) {
      try {
        this.isLoading = true;
        const {
          orders,
          ordersGA,
          menstrualSubscription
        } = await getOrderList(val);
        const menstrualSubscriptionPriceRequests = menstrualSubscription.data.map(subs => {
          const info = subs._source;
          return getMenstrualSubscriptionPrices(this.api, info.duration, info.currency);
        });
        const menstrualSubscriptionPrices = await Promise.all([...menstrualSubscriptionPriceRequests]);

        // Assign each menstrualSubscribtionPrices into menstrualSubscription by index
        menstrualSubscription.data.map((data, index) => {
          data.price = menstrualSubscriptionPrices[index];
        });
        const results = [...orders.data, ...ordersGA.data, ...menstrualSubscription.data];
        results.map(result => {
          var _result$_source$genet, _result$_source, _result$_source$genet2, _result$_source$genet3, _result$_source2, _result$_source2$gene;
          const analystName = "\n            ".concat((_result$_source$genet = (_result$_source = result._source) === null || _result$_source === void 0 ? void 0 : (_result$_source$genet2 = _result$_source.genetic_analyst_info) === null || _result$_source$genet2 === void 0 ? void 0 : _result$_source$genet2.first_name) !== null && _result$_source$genet !== void 0 ? _result$_source$genet : "", "\n            ").concat((_result$_source$genet3 = result === null || result === void 0 ? void 0 : (_result$_source2 = result._source) === null || _result$_source2 === void 0 ? void 0 : (_result$_source2$gene = _result$_source2.genetic_analyst_info) === null || _result$_source2$gene === void 0 ? void 0 : _result$_source2$gene.last_name) !== null && _result$_source$genet3 !== void 0 ? _result$_source$genet3 : "", "\n          ");
          const computeAnalystName = !/^\s*$/.test(analystName) ? analystName : "Unknown Analyst Provider";
          const date = typeof result._source.created_at === "string" ? result._source.created_at.replaceAll(",", "") : result._source.created_at;
          if (result._index === "menstrual-subscription") {
            this.payments.push({
              service_info: {
                name: "Menstrual Calendar ".concat(result._source.duration, " Subscription")
              },
              id: result._id,
              provider: "DeBio Network",
              timestamp: parseInt(date),
              created_at: new Date(parseInt(date)).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric"
              }),
              price: result.price.amount,
              currency: result.price.currency,
              status: "Paid"
            });
          } else {
            var _result$_source$lab_i, _result$_source3, _result$_source3$lab_;
            this.payments.push({
              ...result._source,
              id: result._id,
              provider: result._index === "orders" ? (_result$_source$lab_i = (_result$_source3 = result._source) === null || _result$_source3 === void 0 ? void 0 : (_result$_source3$lab_ = _result$_source3.lab_info) === null || _result$_source3$lab_ === void 0 ? void 0 : _result$_source3$lab_.name) !== null && _result$_source$lab_i !== void 0 ? _result$_source$lab_i : "Unknown Lab Provider" : computeAnalystName,
              timestamp: parseInt(date),
              created_at: new Date(parseInt(date)).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric"
              }),
              price: result._source.service_info.prices_by_currency[0].total_price,
              currency: result._source.currency
            });
          }
        });

        // NOTE: Set unpaid status to always be in the top position
        this.payments.sort((a, b) => {
          if (b.status === "Unpaid") return;else return b.timestamp - a.timestamp;
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    }, 250),
    setButtonBackground(status) {
      const colors = Object.freeze({
        FULFILLED: "#5640a5",
        PAID: "#5640a5",
        CANCELLED: "#9B1B37",
        REFUNDED: "#595959",
        UNPAID: "#E27625"
      });
      return colors[status.toUpperCase()];
    },
    handleDetails(item) {
      let id = "";
      if (item.id) id = item.id;else id = item.orderId;
      if (item["genetic_data_id"]) {
        if (item.status === "Unpaid") this.$router.push({
          name: "customer-request-analysis-payment",
          params: {
            id
          }
        });else this.$router.push({
          name: "customer-payment-details",
          params: {
            id
          }
        });
        return;
      }
      if (item.status === "Unpaid") this.$router.push({
        name: "customer-request-test-checkout",
        params: {
          id
        }
      });else this.$router.push({
        name: "customer-payment-details",
        params: {
          id
        }
      });
    }
  }
};